import React, { useEffect } from "react";

import useScrollFadeIn from "../hooks/useScrollFadeIn";

const Intro = () => {
  const fadeInProps = useScrollFadeIn();
  useEffect(() => {
    const emoji = document.getElementById("wave-emoji");
    if (emoji) {
      emoji.addEventListener("click", () => {
        emoji.classList.add("waving");
        setTimeout(() => emoji.classList.remove("waving"), 1000);
      });
    }
  }, []);

  return (
    <section id="home" {...fadeInProps}>
      <div className="home-container">
        {/* 第一部分：头像 */}
        <div className="profile-section">
          <div className="profile-image">
            <img src="assets/images/my-avatar.gif" alt="Profile" />
          </div>
        </div>

        {/* 第二部分：介绍文本 */}
        <div className="introduction-section">
          <h1>
            Hi, I'm Tao He <span id="wave-emoji">👋</span>
          </h1>
          <h2>A Research-oriented developer</h2>
          <p>✦ Cognitive Science | AI | ML | Research | Full Stack✦</p>
          <em>“Be creative. Be independent. Be thinking.”</em>
        </div>

        {/* 第三部分：社交图标 + 简历按钮 */}
        <div className="social-resume-section">
          <a
            href="https://github.com/dev-helia"
            className="social-icon"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fab fa-github" />
          </a>
          <a
            href="https://www.linkedin.com/in/ht-hetao/"
            className="social-icon"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fab fa-linkedin" />
          </a>
          <a
            href="/resume.pdf"
            className="resume-button"
            target="_blank"
            rel="noreferrer"
          >
            <span>Download CV</span>
            <i className="fas fa-file-download" />
          </a>
        </div>
      </div>
    </section>
  );
};

export default Intro;
