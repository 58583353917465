import React from "react";
import { FaBlog, FaGraduationCap } from "react-icons/fa";

function ExternalLinks() {
  return (
    <section className="external-links-section">
      <p className="external-links-title">Links</p>
      <div className="external-links">
        <a
          href="https://dev-helia.github.io/Tech-Blog-Website/"
          className="external-link-item"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaBlog style={{ marginRight: "8px" }} />
          Blog
        </a>

        <a
          href="https://scholar.google.com/citations?user=xxxx"
          className="external-link-item"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaGraduationCap style={{ marginRight: "8px" }} />
          Scholar
        </a>
      </div>
    </section>
  );
}

export default ExternalLinks;
