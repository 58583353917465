import React, { useEffect, useRef } from "react";


const skills = [
  // Research (120度扇区)
  {
    name: "Huggingface",
    icon: "fa-solid fa-brain",
    style: { top: "15%", left: "70%" },
    category: "research",
  },
  {
    name: "Colab",
    icon: "fa-brands fa-google",
    style: { top: "30%", left: "55%" },
    category: "research",
  },

  // DevOps (120度扇区)
  {
    name: "Docker",
    icon: "fa-brands fa-docker",
    style: { top: "70%", left: "30%" },
    category: "devops",
  },
  {
    name: "Linux",
    icon: "fa-brands fa-linux",
    style: { top: "55%", left: "40%" },
    category: "devops",
  },
  {
    name: "Git",
    icon: "fa-brands fa-git-alt",
    style: { top: "85%", left: "30%" },
    category: "devops",
  },

  // Fullstack (120度扇区)
  {
    name: "React",
    icon: "fa-brands fa-react",
    style: { top: "55%", left: "90%" },
    category: "fullstack",
  },
  {
    name: "Node.js",
    icon: "fa-brands fa-node-js",
    style: { top: "85%", left: "70%" },
    category: "fullstack",
  },
  {
    name: "MongoDB",
    icon: "fa-solid fa-database",
    style: { top: "70%", left: "55%" },
    category: "fullstack",
  },
];

const useParticleBackground = (canvasRef) => {
  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext("2d");
    let animationFrameId;

    const initParticles = () => {
      canvas.width = canvas.clientWidth;
      canvas.height = canvas.clientHeight;

      return Array.from({ length: 80 }).map(() => ({
        x: Math.random() * canvas.width,
        y: Math.random() * canvas.height,
        radius: Math.random() * 1.2,
        alpha: Math.random() * 0.6 + 0.4,
        dx: (Math.random() - 0.5) * 0.3,
        dy: (Math.random() - 0.5) * 0.3,
      }));
    };

    let particles = initParticles();

    const animate = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      particles.forEach((p) => {
        ctx.beginPath();
        ctx.arc(p.x, p.y, p.radius, 0, Math.PI * 2);
        ctx.fillStyle = `rgba(255, 255, 255, ${p.alpha})`;
        ctx.fill();

        // 更新位置
        p.x += p.dx;
        p.y += p.dy;

        // 边界碰撞检测
        if (p.x < 0 || p.x > canvas.width) p.dx *= -1;
        if (p.y < 0 || p.y > canvas.height) p.dy *= -1;
      });

      animationFrameId = requestAnimationFrame(animate);
    };

    const handleResize = () => {
      particles = initParticles();
    };

    animate();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      cancelAnimationFrame(animationFrameId);
    };
  }, [canvasRef]);
};

const RadarGraph = () => {
  const canvasRef = useRef(null);
  useParticleBackground(canvasRef);

  // 计算标签位置（极坐标转笛卡尔）
  const getLabelPosition = (angle, radius) => {
    const radian = (angle * Math.PI) / 180;
    return {
      left: `${50 + radius * Math.cos(radian)}%`,
      top: `${50 - radius * Math.sin(radian)}%`,
    };
  };

  return (
    <div className="radar-container">
      {/* 粒子背景画布 */}
      <canvas
        ref={canvasRef}
        className="particle-canvas"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 0,
        }}
      />

      {/* 雷达图内容 */}
      <div className="radar-content">
        {/* 三层同心圆 */}
        <div className="radar-ring ring1"></div>
        <div className="radar-ring ring2"></div>
        <div className="radar-ring ring3"></div>

        {/* 120度分割线 */}
        <div
          className="radar-line"
          style={{ transform: "rotate(60deg)" }}
        ></div>
        <div
          className="radar-line"
          style={{ transform: "rotate(180deg)" }}
        ></div>
        <div
          className="radar-line"
          style={{ transform: "rotate(300deg)" }}
        ></div>

        {/* 分类标签 */}
        <div
          className="dimension-label research-label"
          style={getLabelPosition(90, 45)}
        >
          🧪 Research
        </div>
        <div
          className="dimension-label devops-label"
          style={getLabelPosition(180, 50)}
        >
          🧰 DevOps
        </div>
        <div
          className="dimension-label fullstack-label"
          style={getLabelPosition(330, 55)}
        >
          🖥️ Fullstack
        </div>

        {/* 技能节点 */}
        {skills.map((skill, i) => (
          <div
            key={i}
            className={`radar-skill ${skill.category}`}
            style={skill.style}
          >
            <i className={skill.icon}></i>
            <span>{skill.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RadarGraph;
