import React, { useState, useEffect } from "react";
import { FaArrowUp } from "react-icons/fa"; // 你也可以用别的 icon

import "../styles/ScrollTop.css"; // ⬅️ 你刚刚写好的样式放这里

const ScrollTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  // 监听滚动
  useEffect(() => {
    const handleScroll = () => {
      setIsVisible(window.scrollY > 200); // 滚动超过 200px 就出现
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // 点击回顶部
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <button
      className={`scroll-top ${isVisible ? "visible" : ""}`}
      onClick={scrollToTop}
      aria-label="Scroll to top"
    >
      <i>
        <FaArrowUp />
      </i>
    </button>
  );
};

export default ScrollTopButton;
