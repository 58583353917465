import React, { useEffect, useRef } from "react";

const skills = [
  { name: "Python", icon: "fa-python", progress: 90 },
  { name: "JavaScript", icon: "fa-js", progress: 85 },
  { name: "Java", icon: "fa-java", progress: 80 },
  { name: "HTML", icon: "fa-html5", progress: 85 },
  { name: "CSS", icon: "fa-css3-alt", progress: 80 },
  { name: "C", icon: "fa-c", progress: 75 },
  { name: "C++", icon: "fa-cuttlefish", progress: 70 },
  { name: "R", icon: "fa-r-project", progress: 60 },
];

const LanguageCircle = () => {
  const circleRefs = useRef([]);

  useEffect(() => {
    circleRefs.current.forEach((el, i) => {
      if (!el) return;
      let current = 0;
      const target = skills[i].progress;
      const step = () => {
        if (current <= target) {
          el.style.background = `conic-gradient(#555 ${
            current * 3.6
          }deg, #e0e0e0 0deg)`;
          current++;
          requestAnimationFrame(step);
        }
      };
      step();
    });
  }, []);

  return (
    <div className="card">
      <h3>Language</h3>
      <div className="circle-chart">
        {skills.map((skill, i) => (
          <div
            className="circle"
            key={skill.name}
            ref={(el) => (circleRefs.current[i] = el)}
          >
            <div className="skill-icon">
              <i className={`fab ${skill.icon}`}></i>
            </div>
            <span>{skill.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LanguageCircle;
