import React from "react";
import useScrollFadeIn from "../hooks/useScrollFadeIn";

const About = () => {
  const fadeInProps = useScrollFadeIn();

  return (
    <section id="about" {...fadeInProps}>
      <h2>About</h2>

      <div className="bio-with-photo">
        <div className="bio-text">
          <div className="quote-block">
            <p className="intro-bio">
              I'm a research-oriented computer science student at Northeastern
              University (Align program), with a dual background in economics
              and public administration. I’m passionate about understanding
              models on a deep level, running systematic experiments, and
              reasoning through system behaviors. My current interests span
              human-centered AI, cognition & language, and research-to-product
              design. I’m excited to contribute to interdisciplinary teams where
              curiosity, rigor, and empathy come together to solve real
              problems.
            </p>
          </div>
        </div>

        <div className="bio-photo">
          <img src="assets/images/me.png" alt="me" />
        </div>
      </div>

      {/* 学术转变路径图 */}

      {/* 教育经历 */}
      {/* 替换原有这两块 */}
      <div className="edu-wrapper">
        {/* 学术转变路径图 */}
        <div className="stage-track side">
          <h3>Track</h3>
          <span className="stage-box">Economics</span>
          <span className="stage-arrow">→</span>
          <span className="stage-box">ML</span>
          <span className="stage-arrow">→</span>
          <span className="stage-box final">AI</span>
        </div>

        {/* 教育经历 */}
        <div className="projects edu-cards side">
          <h3>Education</h3>
          <div className="edu-card">
            <h3>🎓 Nanjing University of Finance and Economics</h3>
            <p>
              <strong>2019 - 2023</strong>
            </p>
            <p>B.A. in Economics</p>
            <p>B.A. in Public Management</p>
          </div>

          <div className="edu-card">
            <h3>🎓 Northeastern University – Boston, MA</h3>
            <p>
              <strong>2024 - Present</strong>
            </p>
            <p>M.S. in Computer Science (Align)</p>
          </div>
        </div>
      </div>

      {/* GitHub Calendar */}
      <div className="github-heatmap">
        <h3>My GitHub Contributions</h3>
        <iframe
          src="https://ghchart.rshah.org/dev-helia"
          frameBorder="0"
          scrolling="0"
          width="100%"
          height="200"
          title="GitHub Contribution Chart"
        />
      </div>
    </section>
  );
};

export default About;
