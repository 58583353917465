import React from "react";
import LanguageCircle from "./LanguageCircle";
import RadarGraph from "./RadarGraph";
import useScrollFadeIn from "../hooks/useScrollFadeIn";

const Skills = () => {
  const fadeInProps = useScrollFadeIn();

  return (
    <section id="skills" {...fadeInProps}>
      <h2>Skills</h2>
      <div className="language-and-radar-wrapper">
        <LanguageCircle />
        <RadarGraph />
        <canvas id="stars-bg"></canvas>
      </div>
    </section>
  );
};

export default Skills;
